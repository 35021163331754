import React from "react";
import product1 from "../../../Assets/products/product1.png";
import product2 from "../../../Assets/products/product2.png";
import product3 from "../../../Assets/products/product3.png";
import product4 from "../../../Assets/products/product4.png";
import product5 from "../../../Assets/products/product5.png";
import product6 from "../../../Assets/products/product6.png";
import product7 from "../../../Assets/products/product7.png";
import product8 from "../../../Assets/products/product8.png";
import product9 from "../../../Assets/products/Banana.png";
import product10 from "../../../Assets/products/Carrot.png";
import product11 from "../../../Assets/products/Cow.png";
import product12 from "../../../Assets/products/Cow2.png";
import product13 from "../../../Assets/products/Eggs.png";

import "./OurProducts.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import OurProductsSwiper from "./OurProductsSwiper/OurProductsSwiper";

function OurProducts() {
  const products = [
    {
      name: "Meat",
      image: `${product1}`,
    },
    {
      name: "Fresh Milk",
      image: `${product2}`,
    },
    {
      name: "Duck",
      image: `${product3}`,
    },
    {
      name: "Chicken",
      image: `${product4}`,
    },
    {
      name: "Corn",
      image: `${product5}`,
    },
    {
      name: "Paddy",
      image: `${product6}`,
    },
    {
      name: "Mango",
      image: `${product7}`,
    },
    {
      name: "Cabbage",
      image: `${product8}`,
    },
    {
      name: "Banana",
      image: `${product9}`,
    },
    {
      name: "Carrot",
      image: `${product10}`,
    },
  ];
  const whatWeDo=[
    {
      name: "Cow",
      image: `${product11}`,
    },
    {
      name: "Eggs",
      image: `${product13}`,
    },
    
    // {
    //   name: "Cow",
    //   image: `${product12}`,
    // },
    {
      name: "Chicken",
      image: `${product4}`,
    },
    {
      name: "Duck",
      image: `${product3}`,
    },
    {
      name: "Corn",
      image: `${product5}`,
    },
    {
      name: "Paddy",
      image: `${product6}`,
    },
    {
      name: "Mango",
      image: `${product7}`,
    },
    {
      name: "Cabbage",
      image: `${product8}`,
    },
    {
      name: "Meat",
      image: `${product1}`,
    },
    {
      name: "Fresh Milk",
      image: `${product2}`,
    },
    
   
    
  ]
  return (
    <div className="products" id="products">
      <div className="container">
        
        <div className="our-products">
          {/* <div className="products-header">
            <p className="products-title">
              OUR <span>PRODUCTS</span>
            </p>
            <p>
              Started with a vision of Making some Contribution to the Country's
              Meat & Dairy Sector. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </p>
          </div> */}
          {/* <div className="products-grid">
            {products.map((product) => (
              <div key={product.name}>
                <img src={product.image} alt="product" />
                <p>{product.name}</p>
              </div>
            ))}
          </div> */}
          {/*  */}
          <div className="our-products-swip ">
            <div className="our-products-swip-content product-slider">
              <div className="products-header">
                
                <p className="products-title">
                What <span>We do</span>
                </p>
              </div>
              {/* <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div> */}
              <Swiper
                pagination={{
                  clickable: true,
                }}
                slidesPerView={2}
                spaceBetween={-5}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper container"
              >
                {whatWeDo.map((product) => (
                  <SwiperSlide key={product.name}>
                    <OurProductsSwiper
                      image={product.image}
                      name={product.name}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="vertical"></div>
            <div className="our-products-swip-content product-slider">
              <div className="products-header">
                <p className="products-title">
                our <span>PRODUCTS</span>
                </p>
              </div>
              {/* <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div> */}
              <Swiper
                pagination={{
                  clickable: true,
                }}
                slidesPerView={2}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper container"
              >
                {products.map((product) => (
                  <SwiperSlide key={product.name}>
                    <OurProductsSwiper
                      image={product.image}
                      name={product.name}
                      title={"OUR PRODUCTS"}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          {/* <Swiper
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper container"
        >
          {products.map((product) => (
            <SwiperSlide  key={product.name}>
              <OurProductsSwiper image={product.image} name={product.name}/>
            </SwiperSlide>
          ))}
        </Swiper> */}
        </div>
      </div>
    </div>
  );
}

export default OurProducts;
