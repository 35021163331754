import React from "react";
import "./TestimonialSwip.css";
import img1 from "../../../../Assets/user/User Profile.svg";
import svg from "../../../../Assets/SVG/Inner-Plugin-Iframe.svg";

function TestimonialSwip({testimonial}) {
  return (
    <section className="testimonial">
      
      <div className="user-img">
        {/* <img className="img-tb" src={img1} alt="" /> */}
        <img className="use_imgm" src={testimonial.image} alt="" />
        <div class="horizontal-line"></div>
        <div className="designation">
          <p id="designation-name">{testimonial.name}</p>
        </div>
      </div>
      <div className="description-img">
          <img src={svg} alt="" />
        </div>
       

      <div className="user-description">
        <div className="user-description-img">
          <img src={svg} alt="" />
        </div>

        <p>{testimonial.title}</p>
      </div>
    </section>
  );
}

export default TestimonialSwip;
