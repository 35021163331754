import React from "react";
import "./OurProductsSwiper.css";
function OurProductsSwiper({image, name}) {
  return (
    <>
      <div className="product">
        <img src={image} alt="product" />
        <p>{name}</p>
      </div>
    </>
  );
}

export default OurProductsSwiper;
