import { AiOutlineMenu } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import navIcon from "../../Assets/banner/BORNIL-AGRO-LOGO-01.png";
import "./LogoMenuWrapper.css";
import { Link } from "react-router-dom";

function LogoMenuWrapper({ menuOpen, setMenuOpen }) {
  const navMenuToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <div className="logo_menu_wrapper">
      <div className="menu_toggler">
        <button onClick={navMenuToggle}>
          {menuOpen ? <RiCloseLine /> : <AiOutlineMenu />}
        </button>
      </div>

      <div className={`menu_large ${menuOpen ? "show" : ""}`}>
        <div className="logo_menu_wrapper-mobile">
          <Link to="/">
            <div>
              <img src={navIcon} alt="" />
            </div>
          </Link>
          <button onClick={navMenuToggle}>
            {menuOpen ? <RiCloseLine /> : <AiOutlineMenu />}
          </button>
        </div>
        <hr />
        <ul>
          <a href="#about">
            <li>About</li>
          </a>
          <a href="#products">
            <li>Products</li>
          </a>
          <a href="#clients">
            <li>Clients</li>
          </a>
          <a href="#testimonial">
            {" "}
            <li>Testimonial</li>
          </a>
        </ul>
      </div>
    </div>
  );
}

export default LogoMenuWrapper;
