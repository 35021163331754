import React from "react";
import bannerVideo from '../../../Assets/banner/banner.mp4'
import banner from '../../../Assets/banner/bannerimage.png'
import "./Banner.css";

function Banner() {
  return (
    <>
      <section className="banner-section">
        <div id="video-container" className="home-banner">
          <video autoPlay muted loop className="banner-video">
            <source src={bannerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div id="image-container" className="banner-image">
          <img
            src={banner}
            alt="Banner"
            layout="fill"
            objectFit="cover"
            loading="lazy"
          />
        </div>

        <div id="content">
          <h1>BORNIL AGRO</h1>
          <p>Started with a vision of Making some Contribution to the<br /> Country's Meat & Dairy Sector.</p>
        </div>
      </section>

      <div id="content-mobile">
        <h1>BORNIL AGRO</h1>
        <p>Started with a vision of Making some Contribution to the<br /> Country's Meat & Dairy Sector.</p>
      </div>
    </>

  );
}

export default Banner;
