import React from "react";
import "./Footer.css";
import footerIcon from "../../Assets/banner/BORNIL-AGRO-LOGO-01.png";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

function Footer() {
  return (
    <section className="footer">
      <div className="container">
        <div className="footer-section">
          <div className="footer-image">
            <img src={footerIcon} alt="" />
          </div>
          <div className="footer-grid">
            <div className="left-section">
              <p>Phone</p>
              <span>+8801841328979</span>

              <p>Email</p>
              <span>bornilagro@gmail.com</span>

              <p>Location</p>
              <span>Holding 6, Ward 8, Horgaon, Rupganj</span>
            </div>
            <div className="middle-section">
              <a href="#about">
                {" "}
                <p>About</p>
              </a>
              <a href="#products">
                <p>Products</p>
              </a>
              <a href="#clients">
                <p>Clients</p>
              </a>
              <a href="#testimonial">
                {" "}
                <p>Testimonial</p>
              </a>
            </div>
            <div className="rigth-section">
              <p>Follow us</p>
              <div className="social-icon">
              <a
                  href="https://www.facebook.com/bornilagro?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon-img"
                >
                  <FaFacebookF />
                </a>

                <div className="social-icon-img">
                  <FaYoutube />
                </div>
                <div className="social-icon-img">
                  <RiInstagramFill />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
