import React from "react";
import "./Counter.css";
function Counter() {
  return (
    <div className="counter_section">
      <div className=" container">
        <div className="counter">
          <div className="counter-items">
            <div className="count-num">
              <p>1</p>
            </div>
            <p>Organic Livestock Farming</p>
          </div>
          <div className="counter-items">
            <div className="count-num">
              <p>2</p>
            </div>
            <p>Organic and Sustainable Practices</p>
          </div>
          <div className="counter-items">
            <div className="count-num">
              <p>3</p>
            </div>
            <p>Family-Friendly Atmosphere</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Counter;
