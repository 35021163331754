import React from "react";
import TestimonialSwip from "./TestimonialSwip/TestimonialSwip";

import "./Testimonial.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import img1 from "../../../Assets/user/user1.jpg";
import img2 from "../../../Assets/user/user2.jpg";
import img3 from "../../../Assets/user/user3.jpg";
import img4 from "../../../Assets/user/user4.jpg";
function Testimonial() {
  const testimonials = [
    {
      image: img1,
      title: `Highly Satisfied. 
      They have provided their committed fresh product.
      Best wishes to Bornil Agro🥰🥰👍👍👍`,
      name: 'Md Saidur Rahman '
    },
    {
      image: img2,
      title: `Highly recommended!
      they provide fresh products.. I am fully satisfied with their service.
      best wishes.🥰🥰`,
      name: 'Shakhawat Hossain Shovon'
    },
    {
      image: img3,
      title: `Their beef and milk qualities are very good.`,
      name: 'Abu Sufian Khan '
    },
    {
      image: img4,
      title: `4 kg order diyechilam. alhamdulillah onak vhalo and fresh chilooo beef ta. recommended for order.`,
      name: 'Taqbir Khan Chowdhury'
    },
  ];

  return (
    <section className="testimonial common_navigation" id="testimonial">
      <div className="container">
        <div className="testimonial-heading">
          <p>
            WHAT clients say <span>about bornil</span>
          </p>
          {/* <p id="testimonial-sub-title">
            Started with a vision of Making some Contribution to the Country's
            Meat & Dairy Sector. Lorem ipsum dolor sit amet, consectetur
            adipiscing sed do eiusmod tempor ut labore{" "}
          </p> */}
        </div>
        <Swiper
          // slidesPerView={1}
          // spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
          }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper container"
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide>
              <TestimonialSwip key={testimonial} testimonial={testimonial}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonial;
