import React from "react";

import Banner from "./Banner/Banner";
import Navbar from "../../Partials/Navbar/Navbar";
import Features from "./Features/Features";
import About from "./About/About";
import Counter from "./Counter/Counter";
import OurProducts from "./OurProducts/OurProducts";
import OurClients from "./OurClients/OurClients";
import Testimonial from "./Testimonial/Testimonial";
import Footer from "../../Partials/Footer/Footer";

function Home() {
  return (
    <>
    
      <Navbar />
      <Banner />
      <Features />
      <About />
      <Counter />
      <OurProducts />
      <Testimonial />
      <OurClients />
      <Footer /> 
      {/* 
      */ 
      }
    </>
  );
}

export default Home;
