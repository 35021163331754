import React, { useState } from "react";
import navIcon from "../../Assets/banner/BORNIL-AGRO-LOGO-01.png";
import "./Navbar.css";
import LogoMenuWrapper from "./LogoMenuWrapper";
import { Link } from "react-router-dom";
// import { RiNotification2Line } from "react-icons/ri";
function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <section className="navbar">
      <div className="navbar_menu">
        <LogoMenuWrapper menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      </div>
      <div className="flex_navbar container">
        <Link to="/">
          <div className="nav_image">
            <img src={navIcon} alt="" />
          </div>
        </Link>
        <div className="nav_item">
          <a href="#about">
            {" "}
            <p>About</p>
          </a>
          <a href="#products">
            <p>Products</p>
          </a>
          <a href="#clients">
            <p>Clients</p>
          </a>
          <a href="#testimonial">
            {" "}
            <p>Testimonial</p>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Navbar;
