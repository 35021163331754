import React from "react";
import about1 from "../../../Assets/About/about1.png";
import about2 from "../../../Assets/About/about2.png";
import mobile1 from "../../../Assets/About/mobile1.png";
import mobile2 from "../../../Assets/About/mobile2.png";
import "./About.css";

function About() {
  const bannerStyle = {
    backgroundImage: `url('${about1}')`,
    backgroundSize: "cover",
  };
  const bannerMobile = {
    backgroundImage: `url('${mobile1}')`,
    backgroundSize: "cover",
  };
  return (
    <section className="about-section" style={bannerStyle} id="about">
      <div className="container">
        <div className="about-section-item">
          
          <img id="web" src={about2} alt="about_image" />
          <img id="mobile" src={mobile2} alt="about_image" />

          <div className="about-content">
            <p className="about-content-title">ABOUT</p>
            <p className="about-content-subtitle">BORNIL AGRO</p>
            <p>
              Started with a vision of Making some Contribution to the Country's
              Meat & Dairy Sector.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
