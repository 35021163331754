import React from "react";
import img1 from "../../../Assets/clients/image1.jpg";
import img2 from "../../../Assets/clients/image2.jpg";
import img3 from "../../../Assets/clients/image3.jpg";
import img4 from "../../../Assets/clients/image4.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./OurClients.css";
// Import Swiper styles
import "swiper/css";

function OurClients() {
  return (
    <div className="container" id="clients">
      <div className="our-clients ">
        <dev className="our-client_card">
          <Swiper
            slidesPerView={3}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="clients-image">
                <img src={img1} alt="img1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="clients-image">
                <img src={img2} alt="img1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="clients-image">
                <img src={img3} alt="img1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="clients-image">
                <img src={img4} alt="img1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="clients-image">
                <img src={img1} alt="img1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="clients-image">
                <img src={img2} alt="img1" />
              </div>
            </SwiperSlide>
          </Swiper>
        </dev>
      </div>
    </div>
  );
}

export default OurClients;
