import React from 'react'
import Features1 from '../../../Assets/Features/healthy-living.svg'
import Features2 from '../../../Assets/Features/natural.svg'
import Features3 from '../../../Assets/Features/organic-food.svg'

import "./Features.css";


export default function Features() {
  return (
    <div className='container'>
      <div className='features container'>
        <div className='features-items'>
          <img src={Features1} alt='Features1' />
          <p>Healthy</p>
        </div>
        <div className='features-items'>
          <img src={Features2} alt='Features1' />
          <p>Fresh</p>
        </div>
        <div className='features-items'>
          <img src={Features3} alt='Features1' />
          <p>Organic</p>
        </div>
      </div>
    </div>
  )
}
